import React from "react"
import { Button } from "./button/button"
import "./footer.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export class Footer extends React.Component {
  constructor(props){
    super(props)
    this.state = { isValidated: false, error: null, success: null }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ error: null, success: null })
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => this.setState({ success: "Form sent" }))
      .catch(() => this.setState({ error: "Error sending form" }))
  }

  render() {
    return (
      <>
      <div id="contact-us"></div>
        <div className="contact-us" id="contact-us">
          <h2>Request Connection</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {this.state.success && <p className="text-success">{this.state.success}</p>}
            {this.state.error && <p className="text-error">{this.state.error}</p>}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              {/* eslint-disable-next-line */}
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <input type="text" placeholder="Name" name="name" onChange={this.handleChange} />
            <input type="email" placeholder="Email" name="email" onChange={this.handleChange} />
            <textarea
              id=""
              cols="30"
              rows="10"
              name="message"
              placeholder="Comment"
              onChange={this.handleChange}
            />
            <div data-netlify-recaptcha="true" />
            <Button type="submit">Send Message</Button>
          </form>
        </div>
        <div className="address">
          <div>
            <img
              src={require("../images/cotton_factory.png")}
              alt="Cotton Factory"
            />
          </div>
          <div>
            <h4>Address</h4>
            <p>270 Sherman Ave N, Unit 102</p>
            <p>Hamilton, ON L8L 6N4</p>
            <p>Canada</p>

            <h4>Phone</h4>
            <a href="tel:​+1-844-321-9898">​+1 (844) 321-9898</a>
          </div>
        </div>
      </>
    )
  }
}
