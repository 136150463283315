import React from 'react';
import './button.scss'

export const Button = (props) => {
  return (
    <button className="button" {...props} />
  )
}
export const LinkButton = (props) => {
  return (
    // eslint-disable-next-line
    <a className="button" {...props} />
  )
}