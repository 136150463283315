import React from "react"
import "./logo.scss"

export const Logo = ({ path }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 298.65 51.52"
    className="logo"
  >
    <title>CENG Technologies Inc.</title>
    <g className="logo-icon">
      <path
        class="cls-1"
        d="M60.32,29.63A25.52,25.52,0,0,0,51,11.2,27.09,27.09,0,0,0,36.47,5.26,26.13,26.13,0,0,0,16.72,12,25.09,25.09,0,0,0,8.46,30.56c0,.8,0,1.62.09,2.42a28.86,28.86,0,0,0,2.7,10,1.07,1.07,0,0,0,.75.57,1,1,0,0,0,.9-.27l.6-.58h0l10.9-10.67a4,4,0,1,0-1.13-.91L12.8,41.4a26.59,26.59,0,0,1-2.19-8.59c-.06-.74-.09-1.5-.09-2.24a23.07,23.07,0,0,1,7.59-17,24,24,0,0,1,18.18-6.2,25,25,0,0,1,13.42,5.49,23.75,23.75,0,0,1,2,34.74c-6,6.08-15.59,8.53-24.46,6.23a34.45,34.45,0,0,1-4.44-1.53l-.05,0a1,1,0,0,1,.11.34l-.17-.29.06-.05a.61.61,0,0,0-.14-.17l.19.12,11.4-10.39a3.9,3.9,0,1,0-.92-1.09L21.87,51.15a3.15,3.15,0,0,0-.95,1.05,1.13,1.13,0,0,0-.05,1c.22.53.74.92,3.09,1.73,1.18.4,2.45.78,2.73.85a28.94,28.94,0,0,0,7.2.91A27.14,27.14,0,0,0,53.14,49,25.54,25.54,0,0,0,60.32,29.63ZM26.25,26.05a2.55,2.55,0,1,1-2.54,2.54A2.54,2.54,0,0,1,26.25,26.05ZM36.66,36.29a2.54,2.54,0,1,1-2.54,2.54A2.54,2.54,0,0,1,36.66,36.29Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M36.6,24.86A3.9,3.9,0,0,0,33.4,31L15.48,48.54a.69.69,0,0,0,0,1,.7.7,0,0,0,1,0L34.38,32a3.87,3.87,0,0,0,2.22.69,3.91,3.91,0,1,0,0-7.82Zm0,6.45a2.54,2.54,0,1,1,2.54-2.54A2.54,2.54,0,0,1,36.6,31.31Z"
        transform="translate(-8.46 -5.17)"
      />
    </g>
    <g className="logo-text">
      <path
        class="cls-1"
        d="M95.2,38.67c-2,3.19-4.44,5.26-8.35,5.26-7.33,0-9.64-6.75-9.64-13,0-10,5.3-13,9.35-13s6.18,2,8,5.36l-2.94,1.49c-1.06-2-2.51-3.81-5-3.81-5,0-6,6.08-6,9.94s.92,10,6,10c2.94,0,4.44-1.78,5.93-4Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M108.22,18.37h15.67v2.89H111.45v7.52h10.27v2.9H111.45V40.6h12.44v2.9H108.22Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M135.66,18.37h4l9.74,21.17.1-.1-.29-6.51V18.37h3.23V43.5h-4L138.7,22.32l-.1.1.29,6.56V43.5h-3.23Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M181.48,41.18a13.42,13.42,0,0,1-7.72,2.75c-7.37,0-9.5-6.85-9.5-13s2.22-13,9.41-13a9.54,9.54,0,0,1,8.05,4.83L179,24.54A6.43,6.43,0,0,0,173.48,21c-5,0-5.84,6.22-5.84,10s.87,9.89,5.93,9.89a8.88,8.88,0,0,0,4.68-1.3V33h-6V30.09h9.26Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M210.56,21.26h-6.95V18.37h17v2.89h-6.8V43.5h-3.23Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M234.14,18.37h15.68v2.89H237.37v7.52h10.28v2.9H237.37V40.6h12.45v2.9H234.14Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M279,38.67c-2,3.19-4.44,5.26-8.35,5.26-7.33,0-9.65-6.75-9.65-13,0-10,5.31-13,9.36-13s6.18,2,8,5.36l-2.94,1.49c-1.06-2-2.51-3.81-5-3.81-5,0-6,6.08-6,9.94s.92,10,6,10c2.94,0,4.44-1.78,5.93-4Z"
        transform="translate(-8.46 -5.17)"
      />
      <path
        class="cls-1"
        d="M290.72,18.37H294V28.78h9.93V18.37h3.24V43.5h-3.24V31.68H294V43.5h-3.23Z"
        transform="translate(-8.46 -5.17)"
      />
    </g>
  </svg>
)
