/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import 'typeface-fira-sans';

import Header from "./header"
import "./layout.scss"
import { Footer } from "./footer";
import { FooterBottom } from "./footer-bottom";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="main-page-content" style={{opacity: 0}}>
          <div className="container pad">
            <main>{children}</main>
            <Footer />
          </div>
          <FooterBottom />
        </div>
      </>
    )}
  />
)

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// const scrollToElement = require('scroll-to-element')

// exports.onRouteUpdate = ({ location }) => {
//   checkHash(location)
// }

// const checkHash = location => {
//   let hash = location.hash
//   if (hash) {
//     scrollToElement(hash, {
//       offset: -90,
//       duration: 1000,
//     })
//   }
// }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
