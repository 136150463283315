import React, { useState } from "react"

export const Dropdown = ({ label, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="dropdown">
      <button className="dropdown-button" onClick={() => setOpen(!open)}>
        {label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>
      <div className={`dropdown-menu ${open ? "open" : "closed"}`}>
        {children}
      </div>
    </div>
  )
}
