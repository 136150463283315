import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import "./header.scss"
import { Logo } from "./logo"
import { Dropdown } from "./dropdown"
import { Hamburger } from "./hamburger";

const Header = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const handleClick = e => {
    if (ref.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <Link to="/" id="Logo"><Logo /></Link>

          <div className="container-right">
            <Dropdown label="Our Work">
              <Link to="/our-work/hitchbot">Hitchbot</Link>
              <Link to="/our-work/airbus">Airbus</Link>
              <Link to="/our-work/british-airways">British Airways</Link>
            </Dropdown>
            <Link to="/#services">Our Services</Link>
            <Link to="#contact-us">Contact Us</Link>
            <button className="hamburger" onClick={() => setOpen(!open)}>
              <Hamburger />
            </button>
          </div>
        </div>
      </header>
      <div className={`sidebar ${open ? 'open' : 'closed'}`} ref={ref}>
        <div className="sidebar-inner">
          <Dropdown label="Our Work">
            <Link to="/our-work/hitchbot">Hitchbot</Link>
            <Link to="/our-work/airbus">Airbus</Link>
            <Link to="/our-work/british-airways">British Airways</Link>
          </Dropdown>
          <Link to="/#services">Our Services</Link>
          <Link to="#contact-us">Contact Us</Link>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
